import { LANGUAGES } from 'utils/constants/general/languages';

import { PageFooterLanguageLinksItem } from './Item/PageFooterLanguageLinksItem';
import { PageFooterLanguageLinksHolder } from './PageFooterLanguageLinks.styled';

type Props = {
  urlByLocale: Record<UserLocale, string | undefined> | undefined;
};

export function PageFooterLanguageLinks({ urlByLocale }: Props) {
  return (
    <PageFooterLanguageLinksHolder>
      {(Object.keys(LANGUAGES) as UserLocale[]).map((locale) => (
        <PageFooterLanguageLinksItem
          key={locale}
          locale={locale}
          localeUrl={urlByLocale?.[locale]}
        />
      ))}
    </PageFooterLanguageLinksHolder>
  );
}
