import merge from 'lodash/merge';

import { PageFooterData } from 'components/PageFooter/types/PageFooterData';

import { pageFooterDataEn, pageFooterUnauthDataEn } from './pageFooterData.en';
import { pageFooterDataEs } from './pageFooterData.es';
import { pageFooterDataPt } from './pageFooterData.pt';

export const pageFooterDataByLocale: Record<UserLocale, PageFooterData> = {
  en: pageFooterDataEn,
  es: pageFooterDataEs,
  pt: pageFooterDataPt,
};

export const pageFooterUnauthDataByLocale: Record<UserLocale, PageFooterData> =
  merge(pageFooterDataByLocale, {
    en: pageFooterUnauthDataEn,
  });
