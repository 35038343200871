import { PageFooterSectionName } from './types/PageFooterData';

export const pageFooterOrderedSectionsMetadata: Array<{
  sectionName: PageFooterSectionName;
  desktopOnly?: true;
}> = [
  { sectionName: 'findAJob', desktopOnly: true },
  { sectionName: 'takeAction', desktopOnly: true },
  { sectionName: 'postOnIdealist', desktopOnly: true },
  { sectionName: 'aboutUs' },
  { sectionName: 'learnMore' },
];
